import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../components/RawHTML';
import Container from '../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">Tools/AnnotStyle.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[AnnotStyle initWithAnnotType:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull instancetype)initWithAnnotType:(PTAnnotType)annotType</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull instancetype)initWithAnnotType:(PTExtendedAnnotType)annotType</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> AnnotStyle.annotType</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, readonly) PTAnnotType annotType</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, readonly) PTExtendedAnnotType annotType</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/AnnotStyleViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> AnnotStyleViewController.presentationManager</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/AnnotTypes.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTExtendedAnnotNameStrikeout</div>
<div class="difference"><span class="status removed">Removed</span> PTExtendedAnnotName3D</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameStrikeOut</div>
<div class="difference"><span class="status added">Added</span> PTExtendedAnnotNameThreeDimensional</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ColorDefaults.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> #def ANNOT_MARKUP</div>
<div class="difference"><span class="status removed">Removed</span> #def ANNOT_HIGHLIGHT</div>
<div class="difference"><span class="status removed">Removed</span> #def ANNOT_TEXTUNDERLINE</div>
<div class="difference"><span class="status removed">Removed</span> #def ANNOT_TEXTSRIKEOUT</div>
<div class="difference"><span class="status removed">Removed</span> #def ANNOT_TEXTSQUIGGLY</div>
<div class="difference"><span class="status removed">Removed</span> #def ANNOT_FREETEXT</div>
<div class="difference"><span class="status removed">Removed</span> #def ANNOT_LINE</div>
<div class="difference"><span class="status removed">Removed</span> #def ANNOT_SQUARE</div>
<div class="difference"><span class="status removed">Removed</span> #def ANNOT_CIRCLE</div>
<div class="difference"><span class="status removed">Removed</span> #def ANNOT_POLYGON</div>
<div class="difference"><span class="status removed">Removed</span> #def ANNOT_POLYLINE</div>
<div class="difference"><span class="status removed">Removed</span> #def ANNOT_INK</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> +[ColorDefaults setDefaultColor:forAnnotType:attribute:colorPostProcessMode:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (void)setDefaultColor:(nonnull UIColor *)color forAnnotType:(PTAnnotType)type attribute:(nonnull NSString *)attribute colorPostProcessMode:(PTColorPostProcessMode)mode</td></tr>
<tr><th>To</th><td class="declaration">+ (void)setDefaultColor:(nonnull UIColor *)color forAnnotType:(PTExtendedAnnotType)type attribute:(nonnull NSString *)attribute colorPostProcessMode:(PTColorPostProcessMode)mode</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[ColorDefaults defaultColorForAnnotType:attribute:colorPostProcessMode:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (nonnull UIColor *)defaultColorForAnnotType:(PTAnnotType)type attribute:(nonnull NSString *)attribute colorPostProcessMode:(PTColorPostProcessMode)mode</td></tr>
<tr><th>To</th><td class="declaration">+ (nonnull UIColor *)defaultColorForAnnotType:(PTExtendedAnnotType)type attribute:(nonnull NSString *)attribute colorPostProcessMode:(PTColorPostProcessMode)mode</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[ColorDefaults defaultColorPtForAnnotType:attribute:colorPostProcessMode:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (nonnull PTColorPt *)defaultColorPtForAnnotType:(PTAnnotType)type attribute:(nonnull NSString *)attribute colorPostProcessMode:(PTColorPostProcessMode)mode</td></tr>
<tr><th>To</th><td class="declaration">+ (nonnull PTColorPt *)defaultColorPtForAnnotType:(PTExtendedAnnotType)type attribute:(nonnull NSString *)attribute colorPostProcessMode:(PTColorPostProcessMode)mode</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[ColorDefaults numCompsInColorPtForAnnotType:attribute:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (int)numCompsInColorPtForAnnotType:(PTAnnotType)type attribute:(nonnull NSString *)attribute</td></tr>
<tr><th>To</th><td class="declaration">+ (int)numCompsInColorPtForAnnotType:(PTExtendedAnnotType)type attribute:(nonnull NSString *)attribute</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[ColorDefaults setDefaultOpacity:forAnnotType:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (void)setDefaultOpacity:(double)opacity forAnnotType:(PTAnnotType)type</td></tr>
<tr><th>To</th><td class="declaration">+ (void)setDefaultOpacity:(double)opacity forAnnotType:(PTExtendedAnnotType)type</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[ColorDefaults defaultOpacityForAnnotType:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (double)defaultOpacityForAnnotType:(PTAnnotType)type</td></tr>
<tr><th>To</th><td class="declaration">+ (double)defaultOpacityForAnnotType:(PTExtendedAnnotType)type</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[ColorDefaults setDefaultBorderThickness:forAnnotType:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (void)setDefaultBorderThickness:(double)thickness forAnnotType:(PTAnnotType)type</td></tr>
<tr><th>To</th><td class="declaration">+ (void)setDefaultBorderThickness:(double)thickness forAnnotType:(PTExtendedAnnotType)type</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[ColorDefaults defaultBorderThicknessForAnnotType:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (double)defaultBorderThicknessForAnnotType:(PTAnnotType)type</td></tr>
<tr><th>To</th><td class="declaration">+ (double)defaultBorderThicknessForAnnotType:(PTExtendedAnnotType)type</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/CreateToolBase.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> CreateToolBase.annotClass</div>
<div class="difference"><span class="status removed">Removed</span> CreateToolBase.annotType</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/EditToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[EditToolbarDelegate editToolbarToggleStylePicker:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/HalfModalPresentationManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> HalfModalPresentationManager.popoverSourceRect</div>
<div class="difference"><span class="status added">Added</span> HalfModalPresentationManager.popoverSourceView</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> HalfModalPresentationManager</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>UIViewControllerTransitioningDelegate</td></tr>
<tr><th>To</th><td>UIPopoverPresentationControllerDelegate, UIViewControllerTransitioningDelegate</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/NoteEditController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[NoteEditController initWithDelegate:annotType:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull instancetype)initWithDelegate:(nonnull id&lt;PTNoteEditControllerDelegate&gt;)delegate annotType:(PTAnnotType)annotType</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull instancetype)initWithDelegate:(nonnull id&lt;PTNoteEditControllerDelegate&gt;)delegate annotType:(PTExtendedAnnotType)annotType</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/Permissions.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTAnnotPermissionKey</div>
<div class="difference"><span class="status removed">Removed</span> PTAnnotPermissionKeyCanCreate</div>
<div class="difference"><span class="status removed">Removed</span> PTAnnotPermissionKeyCanEdit</div>
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolPermissionKey</div>
<div class="difference"><span class="status removed">Removed</span> PTInteractionToolPermissionKeyEnabled</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotationPermission.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> +[PTAnnotationPermission permission]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotationPermission initWithCanCreate:canEdit:]</div>
<div class="difference"><span class="status added">Added</span> PTAnnotationPermission.canCreate</div>
<div class="difference"><span class="status added">Added</span> PTAnnotationPermission.canEdit</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotationPermission isEqualToAnnotationPermission:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotationPermission initWithCoder:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.bookmarksButtonItem</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.bottomToolbarEnabled</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.pageIndicatorEnabled</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.thumbnailSliderController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.viewerSettingsButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.shareButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.searchButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.annotationToolbarButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.thumbnailBrowserButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.navigationListsButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.annotationListHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.outlineListHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.bookmarkListHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.thumbnailsButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.navigationListsButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.thumbnailsViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController showThumbnailsController]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTModel.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> +[PTModel localPropertyKeysForClass:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTModel localDictionaryValueForClass:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTModel.localPropertyKeys</div>
<div class="difference"><span class="status added">Added</span> PTModel.propertyClasses</div>
<div class="difference"><span class="status added">Added</span> +[PTModel propertyKeysForKindOfClass:]</div>
<div class="difference"><span class="status added">Added</span> +[PTModel localDictionaryValueWithModel:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTNavigationListsViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTNavigationListsViewController initWithToolManager:]</div>
<div class="difference"><span class="status added">Added</span> PTNavigationListsViewController.outlineViewController</div>
<div class="difference"><span class="status added">Added</span> PTNavigationListsViewController.annotationViewController</div>
<div class="difference"><span class="status added">Added</span> PTNavigationListsViewController.bookmarkViewController</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTNavigationListsViewController.listViewControllers</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, strong, nullable) NSArray&lt;UIViewController *&gt; *listViewControllers</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nullable) NSArray&lt;UIViewController *&gt; *listViewControllers</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTabbedDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTTabbedDocumentViewController.automaticallySavesDocuments</div>
<div class="difference"><span class="status removed">Removed</span> PTTabbedDocumentViewController.automaticDocumentSavingInterval</div>
<div class="difference"><span class="status removed">Removed</span> PTTabbedDocumentViewController.automaticallyHidesControls</div>
<div class="difference"><span class="status removed">Removed</span> PTTabbedDocumentViewController.automaticControlHidingDelay</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTabbedDocumentViewController configureWithToolManagerConfiguration:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewControllerDelegate tabbedDocumentViewController:willAddDocumentViewController:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController documentViewControllerAtIndex:]</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController.selectedViewController</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/TextMarkupCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> TextMarkupCreate.annotType</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/TextSelectTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[TextSelectTool createTextMarkupAnnot:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)createTextMarkupAnnot:(PTAnnotType)annotType</td></tr>
<tr><th>To</th><td class="declaration">- (void)createTextMarkupAnnot:(PTExtendedAnnotType)annotType</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[TextSelectTool createTextMarkupAnnot:withColor:withComponents:withOpacity:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)createTextMarkupAnnot:(PTAnnotType)annotType withColor:(nonnull PTColorPt *)color withComponents:(int)components withOpacity:(double)opacity</td></tr>
<tr><th>To</th><td class="declaration">- (void)createTextMarkupAnnot:(PTExtendedAnnotType)annotType withColor:(nonnull PTColorPt *)color withComponents:(int)components withOpacity:(double)opacity</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ThumbnailSliderViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> ThumbnailSliderViewController.leadingView</div>
<div class="difference"><span class="status removed">Removed</span> ThumbnailSliderViewController.trailingView</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> ThumbnailSliderViewController.leadingToolbarItem</div>
<div class="difference"><span class="status added">Added</span> ThumbnailSliderViewController.leadingToolbarItems</div>
<div class="difference"><span class="status added">Added</span> ThumbnailSliderViewController.trailingToolbarItem</div>
<div class="difference"><span class="status added">Added</span> ThumbnailSliderViewController.trailingToolbarItems</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/Tool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> Tool.annotClass</div>
<div class="difference"><span class="status added">Added</span> Tool.annotType</div>
<div class="difference"><span class="status added">Added</span> Tool.canEditStyle</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[ToolManager configuration]</div>
<div class="difference"><span class="status removed">Removed</span> -[ToolManager configureWithConfiguration:]</div>
<div class="difference"><span class="status removed">Removed</span> -[ToolManager updateConfiguration:]</div>
<div class="difference"><span class="status removed">Removed</span> -[ToolManager setPermissionsForExtendedAnnotType:canCreate:]</div>
<div class="difference"><span class="status removed">Removed</span> -[ToolManager setPermissionsForExtendedAnnotType:canEdit:]</div>
<div class="difference"><span class="status removed">Removed</span> -[ToolManager setPermissionsForExtendedAnnotType:canCreate:canEdit:]</div>
<div class="difference"><span class="status removed">Removed</span> -[ToolManager isInteractionToolEnabled:]</div>
<div class="difference"><span class="status removed">Removed</span> -[ToolManager setPermissionForInteractionToolType:enabled:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> ToolManager.textAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.linkAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.freeTextAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.lineAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.squareAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.circleAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.polygonAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.polylineAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.highlightAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.underlineAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.squigglyAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.strikeOutAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.stampAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.caretAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.inkAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.popupAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.fileAttachmentAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.soundAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.movieAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.widgetAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.screenAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.printerMarkAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.trapNetAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.watermarkAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.threeDimensionalAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.redactAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.projectionAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.richMediaAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.arrowAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.signatureAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> ToolManager.cloudyAnnotationPermission</div>
<div class="difference"><span class="status added">Added</span> -[ToolManager annotationPermissionForAnnotType:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ToolManagerConfiguration.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> ToolManagerConfiguration</div>
<div class="difference"><span class="status removed">Removed</span> ToolManagerConfiguration.showMenuOnTap</div>
<div class="difference"><span class="status removed">Removed</span> ToolManagerConfiguration.showDefaultSignature</div>
<div class="difference"><span class="status removed">Removed</span> ToolManagerConfiguration.pageIndicatorEnabled</div>
<div class="difference"><span class="status removed">Removed</span> ToolManagerConfiguration.annotPermissions</div>
<div class="difference"><span class="status removed">Removed</span> ToolManagerConfiguration.interactionToolPermissions</div>
<div class="difference"><span class="status removed">Removed</span> +[ToolManagerConfiguration configurationWithBuilder:]</div>
<div class="difference"><span class="status removed">Removed</span> ToolManagerConfigurationBuilder</div>
<div class="difference"><span class="status removed">Removed</span> ToolManagerConfigurationBuilder.showMenuOnTap</div>
<div class="difference"><span class="status removed">Removed</span> ToolManagerConfigurationBuilder.showDefaultSignature</div>
<div class="difference"><span class="status removed">Removed</span> ToolManagerConfigurationBuilder.pageIndicatorEnabled</div>
<div class="difference"><span class="status removed">Removed</span> ToolManagerConfigurationBuilder.annotPermissions</div>
<div class="difference"><span class="status removed">Removed</span> ToolManagerConfigurationBuilder.interactionToolPermissions</div>
<div class="difference"><span class="status removed">Removed</span> -[ToolManagerConfigurationBuilder build]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ToolsMacros.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> #def PT_NSStringFromBOOL</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}